/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {
    ProblemTypeDefinition,
    ProblemTypeDefinitionDetails
} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/problemTypeDefinition/types';
import {NavigateFunction} from 'react-router/dist/lib/hooks';

import {WebStageConfig} from '../../../../config/id4-portal-config';
import {SupportedRegions} from '../../../../state/app/appSlice';
import {
    deleteProblemTypeDefinition,
    getProblemTypeDefinition,
    listProblemTypeDefinitions,
    putProblemTypeDefinition
} from '../../../../utility/id4-mothership-client';
import {RouteName} from '../../../Routing/RoutingPage';
import {ProblemFinderAugmented} from '../../Augmented/ProblemFinderAugmented';
import {IDeleteResource} from '../IDeleteResource';
import {EditResourceComponentType, EditResourceConfiguration, IPutResource} from '../IPutResource';
import {ITableResource, ListResourceComponentType, ListResourceConfiguration} from '../ITableResource';
import {IViewResource, ViewResourceComponentType, ViewResourceConfiguration} from '../IViewResource';


/**
 * Problem type resource config.
 */
export class ProblemTypeResourceConfig implements IPutResource<ProblemTypeDefinition>, IViewResource<ProblemTypeDefinition>, ITableResource<ProblemTypeDefinition>, IDeleteResource<ProblemTypeDefinition> {

    /**
     * Navigate function used to navigate the user to different pages.
     * @private
     */
    private readonly navigate: NavigateFunction;

    /**
     * Constructor for class.
     * @param navigate the navigate function.
     */
    constructor(navigate: NavigateFunction) {
        this.navigate = navigate;
    }

    async retrieveResources(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<ProblemTypeDefinition[]> {
        return await listProblemTypeDefinitions(webStageConfig, selectedRegion);
    }


    retrieveResource(webStageConfig: WebStageConfig, region: SupportedRegions, resourceId: string): Promise<ProblemTypeDefinition> {
        return getProblemTypeDefinition(webStageConfig, region, {problemTypeId: resourceId});
    }

    async deleteResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: ProblemTypeDefinition) {
        await deleteProblemTypeDefinition(webStageConfig, region, {problemTypeId: resource.problemTypeId});
    }

    async putResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: ProblemTypeDefinition): Promise<ProblemTypeDefinitionDetails> {
        return await putProblemTypeDefinition(webStageConfig, region,
            {
                problemTypeId: resource.problemTypeId,
                problemTypeDefinitionDetails: {...resource}
            }
        ).then(response => response.problemTypeDefinition);
    }

    navigateCreateResource(): void {
        this.navigate(`${RouteName.PROBLEM_TYPES}/create`);
    }

    navigateEditResource(resource: ProblemTypeDefinition): void {
        this.navigate(`${RouteName.PROBLEM_TYPES}/${resource.problemTypeId}/edit`);
    }

    navigateViewResource(resource: ProblemTypeDefinition): void {
        this.navigate(`${RouteName.PROBLEM_TYPES}/${resource.problemTypeId}`);
    }

    navigateTableView(): void {
        this.navigate(RouteName.PROBLEM_TYPES);
    }

    resourceName(): string {
        return 'Problem Type';
    }

    initializeDefaultObject(): ProblemTypeDefinition {
        return {
            problemTypeId: undefined,
            domain: undefined,
            focus: undefined,
            logic: undefined,
            scope: undefined,
            problemCategory: undefined,
            displayName: undefined,
            creationTimeEpochMs: new Date().getTime(),
            updateTimeEpochMs: new Date().getTime()
        };
    }

    async editResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<Record<string, EditResourceConfiguration>> {
        let existingProblemTypes = await listProblemTypeDefinitions(webStageConfig, selectedRegion);
        return {
            problemTypeId: {
                isIdentifier: true,
                editable: true,
                helperText: 'The problem type ID',
                label: 'Identifier *',
                componentType: EditResourceComponentType.INPUT,
                validationRegex: new RegExp('^[A-Z]+(?:_[A-Z]+)*$'),
                disallowedValues: existingProblemTypes.map((pt) => pt.problemTypeId)
            },
            domain: {
                helperText: 'The domain of this problem type.',
                label: 'Domain *',
                componentType: EditResourceComponentType.TEXT_BOX
            },
            focus: {
                helperText: 'The focus of this problem type.',
                label: 'Focus *',
                componentType: EditResourceComponentType.TEXT_BOX
            },
            logic: {
                helperText: 'How this problem type is found.',
                label: 'Logic *',
                componentType: EditResourceComponentType.TEXT_BOX
            },
            scope: {
                helperText: 'Where we look for this problem type.',
                label: 'Scope *',
                componentType: EditResourceComponentType.TEXT_BOX
            },
            displayName: {
                helperText: 'The display name for this problem type',
                label: 'Display Name *',
                componentType: EditResourceComponentType.INPUT
            },
            problemCategory: {
                helperText: 'The category of this problem type',
                label: 'Category  *',
                componentType: EditResourceComponentType.INPUT
            }
        };
    }

    async viewResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions, resource: ProblemFinderAugmented): Promise<Record<string, ViewResourceConfiguration<ProblemFinderAugmented>>> {
        return {
            problemTypeId: {
                displayName: 'Identifier',
                componentType: ViewResourceComponentType.TEXT,
            },
            creationTimeEpochMs: {
                displayName: 'Created',
                componentType: ViewResourceComponentType.TIME,
            },
            updateTimeEpochMs: {
                displayName: 'Updated',
                componentType: ViewResourceComponentType.TIME,
            },
            domain: {
                displayName: 'Domain',
                componentType: ViewResourceComponentType.EXPANDABLE_TEXT,
            },
            focus: {
                displayName: 'Focus',
                componentType: ViewResourceComponentType.EXPANDABLE_TEXT,
            },
            logic: {
                displayName: 'Logic',
                componentType: ViewResourceComponentType.EXPANDABLE_TEXT,
            },
            scope: {
                displayName: 'Scope',
                componentType: ViewResourceComponentType.EXPANDABLE_TEXT,
            },
            displayName: {
                displayName: 'Display Name',
                componentType: ViewResourceComponentType.TEXT,
            },
            problemCategory: {
                displayName: 'Category',
                componentType: ViewResourceComponentType.TEXT,
            }
        };
    }

    async listResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<Record<string, ListResourceConfiguration<ProblemTypeDefinition>>> {
        return {
            problemTypeId: {
                displayName: 'Identifier',
                componentType: ListResourceComponentType.TEXT,
                onClick: id => this.navigate(RouteName.PROBLEM_TYPES + `/${id}`),
                sortable: true
            },
            domain: {
                displayName: 'Domain',
                componentType: ListResourceComponentType.TEXT,
                maxValueLength: 30,
            },
            focus: {
                displayName: 'Focus',
                componentType: ListResourceComponentType.TEXT,
                maxValueLength: 30,
            },
            logic: {
                displayName: 'Logic',
                componentType: ListResourceComponentType.TEXT,
                maxValueLength: 30,
            },
            scope: {
                displayName: 'Scope',
                componentType: ListResourceComponentType.TEXT,
                maxValueLength: 30,
            },
            creationTimeEpochMs: {
                displayName: 'Created',
                componentType: ListResourceComponentType.TIME,
                sortable: true
            },
            updateTimeEpochMs: {
                displayName: 'Updated',
                componentType: ListResourceComponentType.TIME,
                sortable: true
            },
            displayName: {
                displayName: 'Display Name',
                componentType: ListResourceComponentType.TEXT,
                sortable: true
            },
            problemCategory: {
                displayName: 'Problem Category',
                componentType: ListResourceComponentType.TEXT,
                sortable: true
            },
        };
    }

    getId(resource: ProblemTypeDefinition): string {
        return resource.problemTypeId;
    }


}
