/*
 * Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Expander from '@amzn/meridian/expander';
import Input from '@amzn/meridian/input';
import Row from '@amzn/meridian/row';
import {SelectOptionProps} from '@amzn/meridian/select/select-option';
import Text from '@amzn/meridian/text';
import TimePicker from '@amzn/meridian/time-picker/time-picker';
import Toggle from '@amzn/meridian/toggle';
import cronTime from 'cron-time-generator';
import React from 'react';

import {cronExpressionToFrequency} from '../../utility/cron-utility';
import SearchSelect from '../utility-views/SearchSelect';

const SchedulingInput = ({
    cronExpression, setCronExpression, cronError, enabled, setEnabled, enabledText, secondaryText
}: {
    cronExpression: string, setCronExpression: React.Dispatch<React.SetStateAction<string>>,
    cronError: string | null,
    enabled: boolean, setEnabled: React.Dispatch<React.SetStateAction<boolean>>,
    enabledText: string, secondaryText: string
}) => {

    const PERIOD_UNITS: SelectOptionProps[] = [
        { label: 'Minutes', value: 'minute' },
        { label: 'Hours', value: 'hour' },
        { label: 'Days', value: 'day' },
    ];

    const [frequency, setFrequency] = React.useState<string>(undefined);
    const [periodUnit, setPeriodUnit] = React.useState<string>(undefined);
    const [startingAt, setStartingAt] = React.useState<string>(undefined);
    const [frequencyInputsDisabled, setFrequencyInputsDisabled] = React.useState<boolean>(false);

    const [open, setOpen] = React.useState<boolean>(false);
    const [manualCronExpression, setManualCronExpression] = React.useState<string>(undefined);

    /**
     * Effect that parses the passed in Cron expression and sets either the fixed period inputs
     * or the manual Cron expression input.
     */
    React.useEffect(() => {
        if (cronExpression !== undefined) {
            // No manualCronExpression means cronExpression is passed in from props and hasn't been updated yet
            // We only want to parse the cronExpression in this case
            if (!manualCronExpression && !cronError) {
                try {
                    const cronFrequency = cronExpressionToFrequency(cronExpression);
                    // We know it's valid and can fit the input if no error was thrown
                    setFrequency(String(cronFrequency.frequency));
                    setPeriodUnit(cronFrequency.periodUnit);
                    setStartingAt(cronFrequency.withTime);
                } catch (e) {
                    console.log(`Error while parsing expression ${cronExpression}`, e);
                    // Set the manual Cron expression field to the input value (which performs its own validation anyways)
                    setOpen(true);
                    setManualCronExpression(cronExpression);
                }
            }
        } else {
            setFrequency(undefined);
            setPeriodUnit(undefined);
            setStartingAt(undefined);
            setManualCronExpression(undefined);
        }
        // We need to watch for changes to cronExpression to populate the fields for existing schedules
    }, [cronExpression]);

    /**
     * Effect that will set the Cron expression using the callback in props.
     */
    React.useEffect(() => {
        if (manualCronExpression) {
            setCronExpression(manualCronExpression);
        } else {
            const exp = generateCronExpression();
            setCronExpression(exp);
        }
    }, [frequency, periodUnit, startingAt, manualCronExpression]);

    /**
     * Effect that will set the starting at time to undefined if the period unit is undefined. This is in the case that
     * someone unselects the period unit and therefore the starting time doesn't make sense.
     */
    React.useEffect(() => {
        if (periodUnit === undefined) {
            setStartingAt(undefined);
        }
    }, [periodUnit]);

    /**
     * Effect that will disable the frequency inputs if the manual input is used.
     */
    React.useEffect(() => {
        if (manualCronExpression) {
            setFrequencyInputsDisabled(true);
        } else {
            setFrequencyInputsDisabled(false);
        }
    }, [manualCronExpression]);

    /**
     * Generates a Cron expression based on the input frequency and period.
     * @returns {string} Cron expression or undefined if nothing is inputted
     */
    function generateCronExpression(): string {

        /**
         * Parses "started at" time to get the minute and hour.
         * @returns {{ minute: number, hour: number }} minute and hour of "started at" time
         */
        function parseStartingAt(): { minute: number, hour: number } {
            if (startingAt) {
                const parsed = startingAt.split(':');
                return { minute: Number(parsed[1]), hour: Number(parsed[0]) };
            } else {
                return undefined;
            }
        }

        if (frequency && Number(frequency) > 0 && periodUnit !== undefined) {
            const freqNum = Number(frequency);
            switch (periodUnit) {
                case 'minute': {
                    return cronTime.every(freqNum).minutes();
                }
                case 'hour': {
                    return cronTime.every(freqNum).hours();
                }
                case 'day': {
                    if (startingAt) {
                        const {minute, hour} = parseStartingAt();
                        return cronTime.every(freqNum).days(hour, minute);
                    } else {
                        return cronTime.every(freqNum).days();
                    }
                }
            }
        } else {
            return '';
        }
    }

    return (
        <>
            <Text type='h300'>Scheduling</Text>
            <Text type='b100' color='secondary'>{secondaryText}</Text>
            <Row alignmentHorizontal='center'>
                <Input
                    label='Frequency'
                    value={frequency}
                    onChange={(val: string) => setFrequency(val === '' ? undefined : val)}
                    type='number'
                    disabled={frequencyInputsDisabled}
                />
                <SearchSelect
                    label='Period units'
                    placeholder=''
                    options={PERIOD_UNITS}
                    isLoading={false}
                    loadingMessage=''
                    selectedValue={periodUnit}
                    setSelectedValue={setPeriodUnit}
                    disabled={frequencyInputsDisabled}
                    width='50%'
                />
            </Row>
            <TimePicker
                label='Time of day to run'
                placeholder='Pick a time'
                value={startingAt}
                onChange={setStartingAt}
                disabled={periodUnit === undefined || periodUnit === 'minute' || periodUnit === 'hour' || frequencyInputsDisabled}
                invalidInputMessage='The starting at value is not a valid time. The correct time format is hour:minute AM/PM.'
            />
            {
                !manualCronExpression && cronExpression && (
                    <Text type='b100' color='secondary'>Cron expression from input: {cronExpression}</Text>
                )
            }
            <Expander open={open} onChange={setOpen} type='inline' title='Define your own Cron expression'>
                <Input
                    label='Cron expression'
                    value={manualCronExpression}
                    onChange={(val: string) => setManualCronExpression(val === '' ? undefined : val)}
                    type='text'
                    disabled={frequency !== undefined || periodUnit !== undefined || startingAt !== undefined}
                />
            </Expander>
            {cronExpression !== undefined && cronError &&
                <Text color={'error'}>{cronError}</Text>
            }
            <Text type='h100'>Enable/disable</Text>
            <Toggle checked={enabled} onChange={setEnabled}>{enabledText}</Toggle>
        </>
    );
};

export default SchedulingInput;
