/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {ProblemFinderRegistration} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/registration/types';
import {ProblemFinderSchedule} from '@amzn/id4-mothership/com/amazon/id4/mothership/model/scheduler';
import {NavigateFunction} from 'react-router/dist/lib/hooks';

import {WebStageConfig} from '../../../../config/id4-portal-config';
import {SupportedRegions} from '../../../../state/app/appSlice';
import {
    deleteProblemFinderRegistration,
    deleteProblemFinderSchedule,
    getProblemFinderRegistration,
    getProblemFinderScheduleForProblemFinderId,
    listProblemFinderRegistrations,
    listProblemFinderSchedule,
    putProblemFinderRegistration,
    putProblemFinderSchedule
} from '../../../../utility/id4-mothership-client';
import {RouteName} from '../../../Routing/RoutingPage';
import {ProblemFinderAugmented} from '../../Augmented/ProblemFinderAugmented';
import {EditResourceComponentType, EditResourceConfiguration} from '../IPutResource';
import {ITableResource, ListResourceComponentType, ListResourceConfiguration} from '../ITableResource';
import {IViewResource, ViewResourceComponentType, ViewResourceConfiguration} from '../IViewResource';


/**
 * Problem finder resource config.
 */
export class ProblemFinderResourceConfiguration implements ITableResource<ProblemFinderAugmented>, IViewResource<ProblemFinderAugmented> {

    /**
     * Navigate function used to navigate the user to different pages.
     * @private
     */
    private readonly navigate: NavigateFunction;

    /**
     * Constructor for class.
     * @param navigate the navigate function.
     */
    constructor(navigate: NavigateFunction) {
        this.navigate = navigate;
    }

    async retrieveResources(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<ProblemFinderAugmented[]> {
        let problemFinders = await listProblemFinderRegistrations(webStageConfig, selectedRegion);

        let schedules = await listProblemFinderSchedule(webStageConfig, selectedRegion);

        let scheduleMap: Record<string, ProblemFinderSchedule> = {};
        schedules.forEach(schedule => {
            scheduleMap[schedule.problemFinderId] = schedule;
        });
        let augmentedPfs: ProblemFinderAugmented[] = [];
        problemFinders.forEach(pf => {
            augmentedPfs.push({
                schedule: pf.problemFinderId in scheduleMap ? scheduleMap[pf.problemFinderId] : undefined,
                ...pf
            });
        });

        return augmentedPfs;
    }

    async deleteResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: ProblemFinderAugmented) {
        await deleteProblemFinderRegistration(webStageConfig, region, {problemFinderId: resource.problemFinderId});
        if (resource.schedule) {
            await deleteProblemFinderSchedule(webStageConfig, region, {problemFinderScheduleId: resource.schedule.problemFinderScheduleId});
        }
    }

    async retrieveResource(webStageConfig: WebStageConfig, region: SupportedRegions, resourceId: string): Promise<ProblemFinderAugmented> {
        let problemFinder = await getProblemFinderRegistration(webStageConfig, region, {problemFinderId: resourceId});
        let schedule = await getProblemFinderScheduleForProblemFinderId(webStageConfig, region, resourceId);
        return {
            schedule: schedule,
            ...problemFinder
        };
    }


    navigateCreateResource(): void {
        this.navigate(`${RouteName.PROBLEM_FINDERS}/create`);
    }

    navigateEditResource(resource: ProblemFinderAugmented): void {
        this.navigate(`${RouteName.PROBLEM_FINDERS}/${resource.problemFinderId}/edit`);
    }

    navigateViewResource(resource: ProblemFinderAugmented): void {
        this.navigate(`${RouteName.PROBLEM_FINDERS}/${resource.problemFinderId}`);
    }

    navigateTableView(): void {
        this.navigate(RouteName.PROBLEM_FINDERS);
    }

    resourceName(): string {
        return 'Problem Finder';
    }

    async editResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<Record<string, EditResourceConfiguration>> {
        let problemFinderRegistrations = await listProblemFinderRegistrations(webStageConfig, selectedRegion);
        return {
            // No custom identifiers here
            problemFinderId: {
                isIdentifier: true,
                editable: true,
                helperText: 'The Problem Finder ID',
                label: 'Problem Finder ID *',
                validationRegex: new RegExp('^[A-Z][a-z]*([A-Z][a-z]*)*$'),
                disallowedValues: problemFinderRegistrations.map(v => v.problemFinderId),
            },
            domain: {
                helperText: 'What is the domain of this problem finder?',
                label: 'Domain *',
                componentType: EditResourceComponentType.TEXT_BOX
            },
            focus: {
                helperText: 'What does this problem finder focus on?',
                label: 'Focus *',
                componentType: EditResourceComponentType.TEXT_BOX
            },
            logic: {
                helperText: 'How does this problem finder work?',
                label: 'Logic *',
                componentType: EditResourceComponentType.TEXT_BOX
            },
            scope: {
                helperText: 'Where does this problem finder look?',
                label: 'Scope *',
                componentType: EditResourceComponentType.TEXT_BOX
            }
        };
    }

    async viewResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions, resource: ProblemFinderAugmented): Promise<Record<string, ViewResourceConfiguration<ProblemFinderAugmented>>> {
        return {
            problemFinderId: {
                displayName: 'Identifier',
                componentType: ViewResourceComponentType.TEXT,
            },
            cti: {
                displayName: 'CTI',
                componentType: ViewResourceComponentType.CTI,
            },
            domain: {
                displayName: 'Domain',
                componentType: ViewResourceComponentType.EXPANDABLE_TEXT,
            },
            focus: {
                displayName: 'Focus',
                componentType: ViewResourceComponentType.EXPANDABLE_TEXT,
            },
            logic: {
                displayName: 'Logic',
                componentType: ViewResourceComponentType.EXPANDABLE_TEXT,
            },
            scope: {
                displayName: 'Scope',
                componentType: ViewResourceComponentType.EXPANDABLE_TEXT,
            },
            schedule: {
                displayName: 'Schedule',
                componentType: ViewResourceComponentType.JSON,
            },
        };
    }

    initializeDefaultObject(): ProblemFinderAugmented {
        return {
            cti: undefined,
            domain: undefined,
            focus: undefined,
            logic: undefined,
            scope: undefined,
            problemFinderId: undefined,
        };
    }

    async listResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions): Promise<Record<string, ListResourceConfiguration<ProblemFinderAugmented>>> {
        return {
            problemFinderId: {
                displayName: 'Identifier',
                componentType: ListResourceComponentType.TEXT,
                onClick: value => this.navigate(RouteName.PROBLEM_FINDERS + `/${value}`),
                sortable: true
            },
            enabled: {
                displayName: 'Enabled',
                componentType: ListResourceComponentType.TAG,
                styleRetrieve: pf => pf.schedule ? pf.schedule.enabled ? 'success' : 'error' : undefined,
                valueRetrieve: pf => pf.schedule ? pf.schedule.enabled : false,
                sortable: true
            },
            cti: {
                displayName: 'CTI',
                componentType: ListResourceComponentType.CTI
            },
            creationTimeEpochMs: {
                displayName: 'Created',
                componentType: ListResourceComponentType.TIME,
                sortable: true
            },
            updateTimeEpochMs: {
                displayName: 'Updated',
                componentType: ListResourceComponentType.TIME,
                sortable: true
            }
        };
    }


    getId(resource: ProblemFinderAugmented): string {
        return resource.problemFinderId;
    }

    async putResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: ProblemFinderAugmented): Promise<ProblemFinderRegistration> {
        let problemFinder = await putProblemFinderRegistration(webStageConfig, region,
            {
                problemFinderId: resource.problemFinderId,
                registrationDetails: {...resource}
            }
        ).then(response => response.registration);

        if (resource.schedule) {
            await putProblemFinderSchedule(webStageConfig, region, {
                problemFinderScheduleId: resource.schedule.problemFinderScheduleId,
                problemFinderScheduleDetails: resource.schedule
            })
        }

        return problemFinder;
    }


}
