/*
 * Copyright 2024 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import {SelectOptionProps} from '@amzn/meridian/select/select-option';

import {WebStageConfig} from '../../../config/id4-portal-config';
import {SupportedRegions} from '../../../state/app/appSlice';

/**
 * The edit resource component types.
 */
export enum EditResourceComponentType {
    SELECT,
    TEXT_BOX,
    INPUT,
    MULTI_SELECT_SEARCHABLE_LIST,
    SINGLE_SELECT_SEARCHABLE_LIST
}


/**
 * The edit resource configuration.
 */
export type EditResourceConfiguration = {
    helperText?: string,
    editable?: boolean
    label?: string
    isIdentifier?: boolean
    acceptedValues?: SelectOptionProps[]
    currentValues?: string[]
    disallowedValues?: string[]
    validationRegex?: RegExp,
    componentType?: EditResourceComponentType
}


/**
 * Interface defining all the methods needed to be able to create/update a resource.
 */
export interface IPutResource<T> {

    /**
     * The name of the resource.
     */
    resourceName(): string

    /**
     * Puts the given resource.
     * @param webStageConfig the web stage config.
     * @param region the region.
     * @param resource the resource to put.
     */
    putResource(webStageConfig: WebStageConfig, region: SupportedRegions, resource: T): Promise<T>;

    /**
     * Retrieves the given resource.
     * @param webStageConfig the web stage config.
     * @param region the region to pull from.
     * @param resourceId the id of the resource.
     */
    retrieveResource(webStageConfig: WebStageConfig, region: SupportedRegions, resourceId: string): Promise<T>

    /**
     * Gets the ID for the resource.
     * @param resource to pull id from.
     */
    getId(resource: T): string

    /**
     * Navigate to view the resource.
     * @param resource to view.
     */
    navigateViewResource(resource: T): void;

    /**
     * Navigate to the list view.
     */
    navigateTableView(): void;

    /**
     * Navigate to edit the resource.
     * @param resource to edit.
     */
    navigateEditResource(resource: T): void;

    /**
     * Initialize a default version of the object.
     */
    initializeDefaultObject(): T

    /**
     * The edit resource configuration. Used to configure the fields in our object that are required and editable.
     * @param webStageConfig the web stage config.
     * @param selectedRegion the region.
     * @param resource the resource to edit.
     */
    editResourceConfiguration(webStageConfig: WebStageConfig, selectedRegion: SupportedRegions, resource: T): Promise<Record<string, EditResourceConfiguration>>

}
